import React from "react";
import { LogoRect } from '../../constant/images';
import { useNavigate } from "react-router-dom";
import "./index.css"
const Header = () => {
    let navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/track-appointment')
    }

    return (
        <div className="header">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <a className="navbar-brand" href={'/'}>
                        <img src={LogoRect} className="img-fluid logo" alt="Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href={'//www.drgangwal.com/'}>
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={'//www.drgangwal.com/about-us/'} target="_blank" rel="noopener noreferrer">
                                    About
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href={"//www.drgangwal.com/specializations/celiac/"} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Specializations
                                </a>
                                <ul className="dropdown-menu">
                                    <li style={{position:"relative"}}><a className="dropdown-item" href={"//www.drgangwal.com/specializations/celiac/"} target="_blank" rel="noopener noreferrer">Celiac disease (Gluten Intolerance)</a></li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/thyroid/"} target="_blank" rel="noopener noreferrer">Thyroid</a></li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/asthma/"} target="_blank" rel="noopener noreferrer">Asthma</a></li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/blood-pressure/"} target="_blank" rel="noopener noreferrer">Blood Pressure</a></li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/diabetes/"} target="_blank" rel="noopener noreferrer">Diabetes</a></li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/gynaecology/"} target="_blank" rel="noopener noreferrer">Gynaecology&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ►</a>
                                        <ul className="dropdown-menu dropdown-submenu" >
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/infertility/"} target="_blank" rel="noopener noreferrer">Infertility</a></li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/pcod-poly-cystic-ovarian-disease/"} target="_blank" rel="noopener noreferrer">PCOD</a></li>
                                             
                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" href={"//www.drgangwal.com/specializations/ankylosis-spondylitis/"} target="_blank" rel="noopener noreferrer">Ankylosis Spondylitis</a></li>
                                    <li><a className="dropdown-item"href={"//www.drgangwal.com/specializations/itp-immune-thrombocytopenia/"} target="_blank" rel="noopener noreferrer">ITP ( Immune thrombocytopenia )</a></li>
                                    <li><a className="dropdown-item"href={"//www.drgangwal.com/specializations/autism/"} target="_blank" rel="noopener noreferrer">Autism</a></li>
                                    <li><a className="dropdown-item"href={"//www.drgangwal.com/specializations/ibs/"} target="_blank" rel="noopener noreferrer">IBS</a></li>
                                    <li><a className="dropdown-item"href={"//www.drgangwal.com/specializations/food-allergy/"} target="_blank" rel="noopener noreferrer">Food Allergy</a></li>
                                    <li><a className="dropdown-item"href={"//www.drgangwal.com/specializations/ulcerative-colitis/"} target="_blank" rel="noopener noreferrer">Ulcerative Colitis</a></li>
                                    <li><a className="dropdown-item"href={"//www.drgangwal.com/specializations/crohns-disease/"} target="_blank" rel="noopener noreferrer">Crohn’s Disease</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={'//www.drgangwal.com/testimonial'} target="_blank" rel="noopener noreferrer">
                                    Testimonials
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={'//www.drgangwal.com/media/'} target="_blank" rel="noopener noreferrer">
                                    Media
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={'//www.drgangwal.com/contact-us-2/'} target="_blank" rel="noopener noreferrer">
                                    Contact Us
                                </a>
                            </li>
                            <li className="nav-item">
                                <button type="button" className="btn btnNext" onClick={() => handleNavigate()}>
                                    Track Appointment
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="appointBtn">
                        <button type="button" className="btn btnNext" onClick={() => handleNavigate()}>
                            Track Appointment
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;