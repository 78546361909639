import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import { useDispatch } from "react-redux";
import * as videoService from "../services/crmServices";

const VideoWrapper = () => {
  var dispatch = useDispatch();
  const [videos, setVideo] = useState([]);

  // Get Video Slider Function
  const fetchVideos = () => {
    dispatch(videoService.getVideos())
      .then((res) => {
        //console.info(res);
        setVideo(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  useEffect(() => {
    fetchVideos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="videoWrapper">
      <div className="card videoCard">
        <div className="card-header">
          <h4 className="card-title">Our Videos</h4>
        </div>
        <div className="card-body">
          <div className="cardBodyInner">
            <Carousel effect="fade" autoplay>
              {videos.map((item, key) => {
                return (
                  <div className="slideVideo" key={key}>
                    <iframe
                      width="100%"
                      height="420"
                      src={item.video}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoWrapper;
