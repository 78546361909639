import React, {useState, useEffect} from "react";
import { DrImgNew } from '../constant/images';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { useDispatch } from "react-redux";
import * as profileService from "../services/crmServices";


const AboutWrapper = () => {

    var dispatch = useDispatch();
    const [ profile, setprofile ] = useState([]);

    // Get Profile Function
    const fetchProfile = () => {
        dispatch(profileService.getprofiles())
        .then((res) => {
            setprofile(res?.data);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }

    useEffect(() => {
        fetchProfile();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div className="aboutWrapper">
            <div className="aboutInner">
                <div className="card aboutCard">
                    <div className="cardCover">
                        <img src={ DrImgNew } className="img-fluid" alt="profile" />
                        {/* <img src={profile?.image ? profile?.image : DrImgNew } className="img-fluid" alt="profile" /> */}
                    </div>
                    <div className="cardBody">
                        <div className="innerBody">
                            <h4 className="profileName">{profile?.title}</h4>
                            <div className="aboutContent">
                                <p>{profile?.content}</p>
                            </div>
                            <div className="socialInfo">
                                <span className="infoIcon">
                                    <a href={profile?.facebook_url} className="socialLink"  target="_blank" rel="noopener noreferrer">
                                        <FaFacebookF/>
                                    </a>
                                </span>
                                <span className="infoIcon">
                                    <a href={profile?.twitter_url} className="socialLink"  target="_blank" rel="noopener noreferrer">
                                        <FaTwitter/>
                                    </a>
                                </span>
                                <span className="infoIcon">
                                    <a href={profile?.instagram_url} className="socialLink"  target="_blank" rel="noopener noreferrer">
                                        <FaInstagram/>
                                    </a>
                                </span>
                                <span className="infoIcon">
                                    <a href={profile?.linkedin_url} className="socialLink"  target="_blank" rel="noopener noreferrer">
                                        <FaLinkedinIn/>
                                    </a>
                                </span> 
                                <span className="infoIcon">
                                    <a href={profile?.youtube_url} className="socialLink"  target="_blank" rel="noopener noreferrer">
                                        <FaYoutube/>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutWrapper;