import React from 'react';
import { Form, Radio } from 'antd';
import moment from 'moment';

const StepOne = (props) => {
    //console.log({ "props": props.statesApp[2].booked_slots_count})
    const handleCitySelection = (city) => {
        props.onSelectCity(city.city_name);
    };

    const handleNextStep = (e) => {
        console.log(e?.target?.value,"event")
        props.onNextStep();
    };


    return (
        <div className='stepWrap'>
            <div className='stepFormItem'>
                {props.statesApp?.length === 0 ?
                    <div className='noData'>
                        <p>No appointments found!</p>
                    </div> :
                    props.statesApp && <Form.Item
                        name="city"
                        label="Select City"
                        className="mb-0"
                        rules={[
                            {
                                required: true,
                                message: 'Please select city.'
                            },
                        ]}
                    >
                        <Radio.Group 
                            onChange={(e) => {
                                const selectedCity = props.statesApp.find((city) => city.city_id === e.target.value);
                                handleCitySelection(selectedCity);
                                props.handleInput('city')(e);
                            }} 
                            className="cityRadioButton"
                        >
                            {props.statesApp?.map((city, index) => {
                                
                                const currentDate = moment();
                                const validAppointmentDates = city.appointment_dates.filter(date =>
                                    moment(date, "DD MMM, YYYY, dddd").isSameOrAfter(currentDate, 'day')
                                );

                                const isDisabled = Object.keys(city.total_slot).some(date => city.total_slot[date] === city.booked_slots_count[date]);
                                
                                if (validAppointmentDates.length > 0) {
                                    return (
                                        <Radio.Button value={city.city_id} id={index} key={index} disabled={isDisabled}>
                                            {city.city_name}
                                        </Radio.Button>
                                    );
                                }

                                return null;
                            })}
                        </Radio.Group>
                    </Form.Item>
                }
                {props.selectedCity &&
                    <Form.Item
                        name="appointDate"
                        className="customDelect mb-0"
                        label="Select Date"
                        rules={[
                            {
                                required: true,
                                message: 'Please select date.'
                            },
                        ]}
                    >
                        <Radio.Group onChange={(e)=>{
                            handleNextStep();
                            props.handleInput('appointDate')(e);

                            }} className="cityRadioButton dateRadioButton">
                            {props.statesApp?.map((city) => {
                                
                                if (props.selectedCity === city.city_id) {
                                    const currentDate = moment();
                                    const sortedDates = city.appointment_dates
                                        .filter((date) => moment(date, "DD MMM, YYYY, dddd").isSameOrAfter(currentDate, 'day'))
                                        .sort((a, b) => moment(a, "DD MMM, YYYY, dddd").diff(moment(b, "DD MMM, YYYY, dddd")));

                                    return sortedDates.map((date, dateIndex) => {
                                        const bookedCount = city.booked_slots_count[date];
                                        const totalCount = city.total_slot[date];
                                        const isDisabled = bookedCount >= totalCount;

                                        return (
                                            <>
                                                <Radio.Button 
                                                    value={isDisabled ? "" : date} 
                                                    disabled={isDisabled} 
                                                    // disabled={isDisabled || isUpcomingDate} 
                                                    key={dateIndex}
                                                >
                                                    {date}
                                                </Radio.Button>
                                                {isDisabled && dateIndex === 0 && (
                                                    <p className='noBookedMsg'>No more slots available for today. Please try again on the next availability. <br/>(आज के लिए कोई और स्लॉट उपलब्ध नहीं है। कृपया अगली तारीख पर पुनः प्रयास करें) </p>
                                                )}
                                            </>
                                        );
                                    });
                                }
                                return null;
                            })}
                        </Radio.Group>
                    </Form.Item>
                }
            </div>
        </div>
    );
}

export default StepOne;