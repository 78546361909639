import React, { useState, useEffect } from "react";
import moment from "moment";
import html2pdf from "html2pdf.js";
import CheckMark from "../assets/images/check-mark.png";
import { Modal } from "antd";

const StepFour = (props) => {
  const [isModalOpen, setIsmodalOpen] = useState(false);

  const showModal = () => {
    setIsmodalOpen(true);
  };

  useEffect(() => {
if(isModalOpen === true) {
  const element = document.getElementById("downloadablePDF");
  const options = {
    margin: [2, 2],
    filename: "appointment.pdf",
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 10, logging: true },
    jsPDF: { unit: "em", format: "letter", orientation: "portrait" },
  };

  html2pdf().set(options).from(element).save();
  setIsmodalOpen(false);
}
  },[isModalOpen]);

  return (
    <>
      <div className="stepWrap">
        <div className="stepFormItem">
          <div className="row">
            <div className="col-md-12">
              <div className="thankYouCard" id="capture">
                <table
                  className="table"
                  width={"100%"}
                  cellPadding={0}
                  cellSpacing={0}
                  border={0}
                >
                  <tbody>
                    <tr>
                      <td style={{ borderBottom: "0", padding: "0" }}>
                        <table
                          className="table"
                          width={"100%"}
                          cellPadding={0}
                          cellSpacing={0}
                          border={0}
                          style={{ borderBottom: "0", marginBottom: "0" }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderBottom: "0",
                                  padding: "0",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  style={{
                                    display: "block",
                                    lineHeight: "50px",
                                    fontSize: "48px",
                                    background: "#085710",
                                    color: "#FFF",
                                    width: "60px",
                                    height: "60px",
                                    margin: "0 auto 16px",
                                    border: "1px solid #FFF",
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                    boxShadow:
                                      "0px 10px 10px 2px rgba(0, 0, 0, 0.2)",
                                  }}
                                >
                                  <img
                                    src={CheckMark}
                                    className="img-fluid"
                                    alt="check"
                                    style={{
                                      width: "65%",
                                    }}
                                  />
                                </span>
                                <h2
                                  className="thankYouTitle"
                                  style={{
                                    fontSize: "32px",
                                    fontFamily: "Segoe Script",
                                    fontWeight: "bold",
                                    color: "#085710",
                                  }}
                                >
                                  Congratulations!
                                </h2>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderBottom: "0",
                          padding: "0",
                          textAlign: "center",
                        }}
                      >
                        <table
                          className="table"
                          width={"100%"}
                          cellPadding={0}
                          cellSpacing={0}
                          border={0}
                          style={{ borderBottom: "0", marginBottom: "0" }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ borderBottom: "0", padding: "0" }}>
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    marginBottom: "16px",
                                    color: "#000",
                                  }}
                                >
                                  You have successfully booked your appointment.
                                </h4>
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                    marginBottom: "16px",
                                  }}
                                >
                                  आपने सफलतापूर्वक अपना अपॉइंटमेंट बुक कर लिया
                                  है!
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "0", padding: "0" }}>
                        <table
                          className="table"
                          width={"100%"}
                          cellPadding={0}
                          cellSpacing={0}
                          border={0}
                          style={{ borderBottom: "0", marginBottom: "0" }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderBottom: "0",
                                  padding: "0",
                                  textAlign: "center",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                  }}
                                >
                                  Your slot number is: {props.isSlot}
                                </h4>
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                    marginBottom: "16px",
                                  }}
                                >
                                  आपका स्लॉट नंबर है: {props.isSlot}
                                </h4>
                              </td>
                              <td
                                style={{
                                  borderBottom: "0",
                                  padding: "0",
                                  textAlign: "center",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Center Name: {props.selectedCityName}
                                </h4>
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                    textTransform: "capitalize",
                                    marginBottom: "16px",
                                  }}
                                >
                                  केंद्र का नाम: {props.selectedCityName}
                                </h4>
                              </td>
                              <td
                                style={{
                                  borderBottom: "0",
                                  padding: "0",
                                  textAlign: "center",
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                  }}
                                >
                                  Date:{" "}
                                  {moment(props.isDate).format("DD/MM/YYYY")}
                                </h4>
                                <h4
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                    marginBottom: "16px",
                                  }}
                                >
                                  तारीख:{" "}
                                  {moment(props.isDate).format("DD/MM/YYYY")}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "0", padding: "0" }}>
                        <table
                          className="table"
                          width={"100%"}
                          cellPadding={0}
                          cellSpacing={0}
                          border={0}
                          style={{ borderBottom: "0", marginBottom: "0" }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderBottom: "0",
                                  padding: "0",
                                  textAlign: "center",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#666",
                                    textAlign: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  You may take screenshot of this page for
                                  future reference.
                                </p>
                                <p
                                  style={{
                                    color: "#666",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  आप भविष्य के लिए इस पेज का स्क्रीनशॉट ले सकते
                                  हैं!
                                </p>
                                <p
                                  style={{
                                    color: "#666",
                                    textAlign: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  The payment for booking should be made onsite
                                  at the designated payment counter.
                                </p>
                                <p
                                  style={{
                                    color: "#666",
                                    textAlign: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  For now we do not accept any form of online
                                  payments for your appointment.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pdfButton">
                <button onClick={showModal} className="btn btnNext btnFinish">
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={false}
        className="pdfModal"
        closable={false}
      >
        <div>
          <div className="modal-body" id="downloadablePDF">
            <div className="thankYouCard">
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
               <img style={{width:"150px"}} src="/logo.png" alt="logo"/>
              </div>
              <table
                className="table"
                width={"100%"}
                cellPadding={0}
                cellSpacing={0}
                border={0}
              >
                <tbody>
                  <tr>
                    <td style={{ borderBottom: "0", padding: "0" }}>
                      <table
                        className="table"
                        width={"100%"}
                        cellPadding={0}
                        cellSpacing={0}
                        border={0}
                        style={{ borderBottom: "0", marginBottom: "0" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderBottom: "0",
                                padding: "0",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  display: "block",
                                  lineHeight: "50px",
                                  fontSize: "48px",
                                  background: "#085710",
                                  color: "#FFF",
                                  width: "60px",
                                  height: "60px",
                                  margin: "0 auto 16px",
                                  border: "1px solid #FFF",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  boxShadow:
                                    "0px 10px 10px 2px rgba(0, 0, 0, 0.2)",
                                }}
                              >
                                <img
                                  src={CheckMark}
                                  className="img-fluid"
                                  alt="check"
                                  style={{
                                    width: "65%",
                                  }}
                                />
                              </span>
                              <h2
                                className="thankYouTitle"
                                style={{
                                  fontSize: "32px",
                                  fontFamily: "Segoe Script",
                                  fontWeight: "bold",
                                  color: "#085710",
                                }}
                              >
                                Congratulations!
                              </h2>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "0",
                        padding: "0",
                        textAlign: "center",
                      }}
                    >
                      <table
                        className="table"
                        width={"100%"}
                        cellPadding={0}
                        cellSpacing={0}
                        border={0}
                        style={{ borderBottom: "0", marginBottom: "0" }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ borderBottom: "0", padding: "0" }}>
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  marginTop:"10px"
                                }}
                              >
                                You have successfully booked your appointment.
                              </h4>
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                 marginBottom:"15px"
                                }}
                              >
                                आपने सफलतापूर्वक अपना अपॉइंटमेंट बुक कर लिया है!
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: "0", padding: "0" }}>
                      <table
                        className="table"
                        width={"100%"}
                        cellPadding={0}
                        cellSpacing={0}
                        border={0}
                        style={{ borderBottom: "0", marginBottom: "0" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderBottom: "0",
                                padding: "0",
                                textAlign: "center",
                               
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                }}
                              >
                                Your slot number is: {props.isSlot}
                              </h4>
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  marginBottom: "16px",
                                }}
                              >
                                आपका स्लॉट नंबर है: {props.isSlot}
                              </h4>
                            </td>
                            <td
                              style={{
                                borderBottom: "0",
                                padding: "0",
                                textAlign: "center",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  textTransform: "capitalize",
                                }}
                              >
                                Center Name: {props.selectedCityName}
                              </h4>
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  textTransform: "capitalize",
                                  marginBottom: "16px",
                                }}
                              >
                                केंद्र का नाम: {props.selectedCityName}
                              </h4>
                            </td>
                            <td
                              style={{
                                borderBottom: "0",
                                padding: "0",
                                textAlign: "center",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                }}
                              >
                                Date:{" "}
                                {moment(props.isDate).format("DD/MM/YYYY")}
                              </h4>
                              <h4
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  marginBottom: "16px",
                                }}
                              >
                                तारीख:{" "}
                                {moment(props.isDate).format("DD/MM/YYYY")}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: "0", padding: "0" }}>
                      <table
                        className="table"
                        width={"100%"}
                        cellPadding={0}
                        cellSpacing={0}
                        border={0}
                        style={{ borderBottom: "0", marginBottom: "0" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderBottom: "0",
                                padding: "0",
                                textAlign: "center",
                                marginTop:"15px"
                              }}
                            >
                              {/* <p
                                style={{
                                  color: "#666",
                                  textAlign: "center",
                                  fontSize: "14px",
                                 
                                }}
                              >
                                You may take screenshot of this page for future
                                reference.
                              </p>
                              <p
                                style={{
                                  color: "#666",
                                  textAlign: "center",
                                  fontSize: "14px",
                                 marginTop:"0",
                                 paddingTop:"0"
                                }}
                              >
                                आप भविष्य के लिए इस पेज का स्क्रीनशॉट ले सकते
                                हैं!
                              </p> */}

<h5
                                style={{
                                  fontSize: "14px",
                                  color: "#666",
                                }}
                              >
                                You may take screenshot of this page for future
                                reference.
                              </h5>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  color: "#666",
                                 marginBottom:"15px"
                                }}
                              >
                                आप भविष्य के लिए इस पेज का स्क्रीनशॉट ले सकते
                                हैं!
                              </h5>
                             
                              <h5
                                style={{
                                  color: "#666",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  marginTop:"15px"
                                }}
                              >
                                The payment for booking should be made onsite at
                                the designated payment counter.
                              </h5>
                              <h5
                                style={{
                                  color: "#666",
                                  textAlign: "center",
                                  fontSize: "14px",
                                }}
                              >
                                For now we do not accept any form of online
                                payments for your appointment.
                              </h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default StepFour;
