import React, { useState, useEffect } from 'react';
import { Form, Input, Select, InputNumber, Modal, Radio, Spin } from 'antd';
import * as oldPatientService from "../services/getOldPatient";
import { useDispatch } from "react-redux";

const StepThree = (props) => {
    const { Option } = Select;
    //console.warn('propsStepThree', props);
    var dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState();
    const [oldPatientData, setOldPatientData] = useState([]);
    const [isPatient, setIsPatient] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState('');

    const hideModal = () => {
        setIsModalOpen(false)
    }

    const onChange = (e) => {
        console.log('radio checked', e.target.value, isChecked);
        setValue(e.target.value);
        setIsChecked(true);
    };

    // Fetch Old Patient Data 
    const fetchOldPatientData = (mobile_num) => {
        dispatch(oldPatientService.getOldPatient(mobile_num))
            .then((res) => {
                //console.info(res);
                setOldPatientData(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    const handleNumber = (e) => {
        // console.log('ohk', e.target.value);

        let num = e.target.value;
        setInputValue(num)
        if (num.length === 10) {
            setIsLoading(true);
            fetchOldPatientData(num)
            setIsLoading(false)
        }

    }


    const handleChange = (value) => {
        let patientData = oldPatientData.patients;
        let patient = patientData[value.value] ? patientData[value.value][0] : null;
        setIsPatient(patient);
        setSelectValue(patient);
    };

    const handleSubmit = () => {
        if (oldPatientData && oldPatientData.success) {
            props.handlePatientData(isPatient);
            setIsModalOpen(false);
        } else {
            setIsModalOpen(false);
        }
    }

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPatient]);
    

    return (
        <div className='stepWrap'>
            <div className='stepFormItem'>
                <div className='row'>
                    <div className='col-md-4'>
                        <Form.Item
                            name="pname"
                            label="Name (नाम)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter name.'
                                },
                            ]}
                        >
                            <Input
                                className='form-control'
                                placeholder='Name'
                                onChange={props.handleInput('pname')}

                            />
                        </Form.Item>
                    </div>
                    <div className='col-md-4'>
                        <Form.Item
                            name="age"
                            label="Age (आयु)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter age.'
                                },

                            ]}
                        >
                            <InputNumber
                                className='form-control'
                                placeholder='Age'
                                onChange={props.handleInput('age')}
                                defaultValue={props.formInput.age}
                                maxLength={3}
                                minLength={1}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-md-4'>
                        <Form.Item
                            name="gender"
                            label="Gender (लिंग)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select gender.'
                                },
                            ]}
                        >
                            <Select
                                className="customSelect"
                                popupClassName="customSelectDrop"
                                labelInValue
                                placeholder="Select Gender"
                                onChange={props.handleInput('gender')}
                                defaultValue={props.formInput.gender}
                                options={[
                                    {
                                        value: '',
                                        label: 'Select Gender',
                                    },
                                    {
                                        value: 'Male',
                                        label: 'Male',
                                    },
                                    {
                                        value: 'Female',
                                        label: 'Female',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-md-4'>
                        <Form.Item
                            name="number"
                            label="Phone Number (फ़ोन नंबर)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter phone number.'
                                },
                                {
                                    pattern: /^(\+\d{1,3})?(\d{10})$/,
                                    message: 'Invalid mobile number format.'
                                }
                            ]}
                        >
                            <Input
                                className='form-control'
                                placeholder='Mobile Number'
                                onChange={props.handleInput('number')}
                                defaultValue={props.formInput.number}
                                maxLength={10}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-md-4'>
                        <Form.Item
                            name="wnumber"
                            label="WhatsApp Number (नंबर)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter whatsapp number.'
                                },
                                {
                                    pattern: /^(\+\d{1,3})?(\d{10})$/,
                                    message: 'Invalid mobile number format.'
                                }
                            ]}
                        >
                            <Input
                                className='form-control'
                                placeholder='WhatsApp Number'
                                onChange={props.handleInput('wnumber')}
                                defaultValue={props.formInput.wnumber}
                                maxLength={10}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-md-4'>
                        <Form.Item
                            name="patientCity"
                            label="City (शहर)"
                        >
                            <Input
                                className='form-control'
                                placeholder='City'
                                onChange={props.handleInput('patientCity')}
                                defaultValue={props.formInput.patientCity}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-md-12'>
                        <Form.Item
                            name="disease"
                            label="Disease type (रोग प्रकार)"
                        >
                            <Input
                                className='form-control'
                                placeholder='Disease Description'
                                onChange={props.handleInput('disease')}
                                defaultValue={props.formInput.disease}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <Modal
                title="Choose an option"
                open={isModalOpen}
                onCancel={hideModal}
                onOk={hideModal}
                className='overviewModal'
                footer={
                    [
                        <button 
                            type='button' 
                            className='btn btnNext' 
                            onClick={handleSubmit}
                            disabled={value === 2 ? false : inputValue.trim() === '' || selectValue === ''}
                        >
                            Submit
                        </button>
                    ]
                }
            >
                <div className='modalInner'>
                    <div className='mb-3'>
                        <label className='mb-2'>Select Type</label><br />
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>Old Patient</Radio>
                            <Radio value={2}>New Patient</Radio>
                        </Radio.Group>
                    </div>
                    {value === 1 &&
                        <div className='mb-3'>
                            <label className='mb-2'>Mobile Number</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter your mobile number here...'
                                onChange={(e) => handleNumber(e)}
                                value={inputValue}
                            />
                        </div>
                    }
                    {value === 1 && oldPatientData && oldPatientData.success ?
                        <div className='mb-3 customDelect'>
                            {isLoading ? (
                                <Spin />
                            ) : (
                                <>
                                    <label className='mb-2'>Select Patient</label>
                                    <Select
                                        className="customSelect"
                                        popupClassName="customSelectDrop"
                                        labelInValue
                                        style={{
                                            width: "100%",
                                            height: 'auto'
                                        }}
                                        placeholder="Select Patient"
                                        onChange={handleChange}
                                    >
                                        {oldPatientData.success && Object.keys(oldPatientData.patients).map((item, i) => {
                                            return (
                                                <Option value={item} key={i}>{item}</Option>
                                            )
                                        })}
                                    </Select>
                                </>
                            )}
                        </div> : ''
                    }
                </div>
            </Modal>
        </div>
    );
}

export default StepThree;