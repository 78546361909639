import React, { useState, useEffect } from "react";
import { Carousel } from 'antd';
import { BsClipboardCheck } from 'react-icons/bs';
import { useDispatch } from "react-redux";
import * as storiesService from "../services/crmServices";

const SuccessStory = () => {
    var dispatch = useDispatch();
    const [ stories, setStories ] = useState([]);

    // Get Stories Function
    const fetchStories = () => {
        dispatch(storiesService.getStories())
        .then((res) => {
            console.info(res);
            setStories(res.data);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }

    useEffect(() => {
        fetchStories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        // Stories Wrapper Start
        <div className="feedbackWrapper">
            <div className="card feedbackCard">
                <div className="card-header">
                    <h4 className="card-title">Success Story</h4>
                </div>
                <div className="card-body">
                    <div className="cardBodyInner">
                        <Carousel effect="fade" autoplay dots={false}>
                            {stories.map((item, key) => {
                                return(
                                    <div className="clientFeedSlide" key={key}>
                                        <div className="topRow">
                                            <p className="icon">
                                                <BsClipboardCheck />
                                            </p>
                                            <h4 className="clientName">{item.name}</h4>
                                            <h5 className="designation">{item.subtitle}</h5>
                                        </div>
                                        <div className="card clientFeedCard">
                                            <div className="card-body">
                                                <p>{item.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        // Stories Wrapper End
    );
}

export default SuccessStory;