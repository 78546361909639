import Http from "../Http";
import { LIVE_URL } from "../Helper";

export function bookAppointment(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post( LIVE_URL + 'api/patientappointments', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}