import Http from "../Http";
import { LIVE_URL } from "../Helper";


export function getTestimonials() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getTestimonial')
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getStories() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getStory')
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getImages() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getSlider')
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getVideos() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getVideo')
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getprofiles() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getProfile')
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
