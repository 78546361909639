import { AiOutlineCheck } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'

const ThankYouPage = () => {
    let navigate = useNavigate();

    return (
        <div className="thankYouWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="thankYouCard">
                                <span className='icon'><AiOutlineCheck /></span>
                                <h2 className="thankYouTitle">
                                    Thank You !
                                </h2>
                                <h4>You have successfully booked your appointment.</h4>
                                <p>The payment for booking should be made onsite at the designated payment counter.</p>
                                <p>For now we do not accept any form of online payments for your appointment.</p>
                                <button type='button' className='btn btnNext mt-5' onClick={() => navigate('/')}>
                                    Go to home
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYouPage;