import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    // console.warn({ response });
    return response;
  },
  (error) => {
    console.warn(error);
    return Promise.reject(error);
  }
);  
export default axios;