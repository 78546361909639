const Logo = require('../assets/images/logoSmall.png');
const LogoNewBg = require('../assets/images/logoNewBg.png');
const LogoNew = require('../assets/images/logoLarge.png');
const LogoRect = require('../assets/images/logoUpdateRect.png');
const DrImg = require('../assets/images/drImg.jpg');
const DrImgNew = require('../assets/images/img.jpg');
const SlideOne = require('../assets/images/slide-1.jpg');
const SlideTwo = require('../assets/images/slide-2.jpg');
const SlideThree = require('../assets/images/slide-3.jpg');


export {Logo, DrImg, DrImgNew, SlideOne, SlideTwo, SlideThree, LogoNew, LogoNewBg, LogoRect };